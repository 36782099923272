import Block from "../../../../libs/Block";
import Registration from "../../../../libs/Registration";
import * as FilePond from 'filepond';
import 'filepond/dist/filepond.min.css';
import "./file.scss";
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
class File extends Block {
    static get blockName() {
        return "b-file";
    }
    pond;
    onInit() {
        const it = this;
        const input = it.el.querySelector('input');
        const placeholder = it.el.dataset.placeholder;
        const fileTypes = it.el.dataset.fileTypes;
        FilePond.registerPlugin(FilePondPluginFileValidateType);
        FilePond.registerPlugin(FilePondPluginFileValidateSize);
        if (input && fileTypes) {
            this.pond = FilePond.create(input, {
                labelIdle: placeholder,
                maxParallelUploads: 10,
                maxFiles: 10,
                required: true,
                acceptedFileTypes: JSON.parse(fileTypes) || [],
                maxFileSize: '5MB',
                labelInvalidField: 'Ошибка',
                labelFileSizeNotAvailable: 'Ошибка',
                labelFileLoadError: 'Ошибка',
                labelFileProcessingError: 'Ошибка',
                labelFileProcessingRevertError: 'Ошибка',
                labelFileRemoveError: 'Ошибка',
                labelMaxFileSizeExceeded: 'Ошибка',
                labelMaxFileSize: 'Файл слишком большой',
                labelMaxTotalFileSizeExceeded: 'Ошибка',
                labelMaxTotalFileSize: 'Файл слишком большой',
            });
        }
    }
}
Registration.register(File);
export default File;
