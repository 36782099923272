import Block from "../../../../libs/Block";
import Registration from "../../../../libs/Registration";
import "./textarea.scss";
class Textarea extends Block {
    static get blockName() {
        return "b-textarea";
    }
    onInit() {
        const it = this;
        const textarea = it.el.querySelector("textarea");
        textarea?.addEventListener("input", () => {
            this.el.dataset.required && this.validate(it);
        });
        textarea?.addEventListener("focusout", () => {
            this.el.dataset.required && this.validate(it);
        });
    }
    validate(it) {
        const textarea = it.el.querySelector("textarea");
        let label = it.el.querySelector("label");
        const requiredText = it.el.dataset.requiredText;
        if (label && textarea) {
            if (it.el.dataset.required) {
                if (textarea.value) {
                    it.el.classList.remove("error");
                    label.innerHTML = "";
                }
                else {
                    it.el.classList.add("error");
                    label.innerHTML = requiredText || "";
                    return;
                }
            }
        }
    }
}
Registration.register(Textarea);
export default Textarea;
