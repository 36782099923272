import "normalize.css";
import "../styles/app.scss";
import Registration from "../libs/Registration";
// Добавлено для обработки prettier
// import "../styles/config.scss";
// import "../styles/mixins.scss";
// import "../styles/utils.scss";
import "../block/site/grid";
import "../block/common/form-fields/default";
import "../block/common/form-fields/email";
import "../block/common/form-fields/select";
import "../block/common/form-fields/textarea";
import "../block/common/form-fields/checkbox";
import "../block/common/form-fields/phone";
import "../block/common/form-fields/file";
import "../block/common/form-constructor/";
(function () {
    "use strict";
    document.addEventListener("DOMContentLoaded", () => {
        Registration.blocksInit();
    });
})();
