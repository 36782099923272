import Block from "../../../libs/Block";
import Registration from "../../../libs/Registration";
import "./form-constructor.scss";
class FormConstructor extends Block {
    static get blockName() {
        return "b-form-constructor";
    }
    onInit() {
        const it = this;
        const form = it.el.querySelector('.form');
        const btn = it.el.querySelector(`.${FormConstructor.blockName}__btn`);
        const files = document.querySelectorAll(".b-file");
        const citySelect = it.el.querySelector('#city');
        const city = citySelect?.closest(".b-select") || null;
        const storageSelect = it.el.querySelector('#shop');
        const storage = storageSelect?.closest(".b-select") || null;
        if (city && storage) {
            const cityInstance = Registration.getBlock(city);
            const storageInstance = Registration.getBlock(storage);
            cityInstance.choices?.passedElement.element.addEventListener("choice", e => {
                setTimeout(() => {
                    fetch(`/api/getShops/?city=${cityInstance.choices?.getValue(true)}`).then(e0 => {
                        e0.json().then(e => {
                            const result = [];
                            for (const [key, value] of Object.entries(e)) {
                                result.push({
                                    'value': e[key].value,
                                    'label': e[key].value,
                                });
                            }
                            storageInstance.choices?.clearChoices();
                            storageInstance.choices?.setChoices(result);
                        });
                    });
                }, 0);
            });
        }
        if (btn && form) {
            const action = form.getAttribute('action') || '';
            const numberElement = form.querySelector('.b-phone');
            const checkboxes = form.querySelectorAll('[type=checkbox]');
            btn.addEventListener("click", function (e) {
                e.preventDefault();
                const formData = new FormData(form);
                files.forEach(file => {
                    const fileInstance = Registration.getBlock(file);
                    if (fileInstance && fileInstance.pond) {
                        const pondFiles = fileInstance.pond.getFiles();
                        for (let i = 0; i < pondFiles.length; i++) {
                            formData.append(fileInstance.pond.name + '[]', pondFiles[i].file);
                        }
                    }
                });
                if (numberElement) {
                    const phone = Registration.getBlock(numberElement);
                    formData.set('number', (phone.initTel?.getSelectedCountryData().dialCode || '') + phone.value);
                }
                checkboxes.forEach(ch => {
                    if (ch.dataset.crmType == 'user') {
                        formData.set('UF_CRM_NEWSLETTER_CONSENT', ch.id);
                    }
                    if (ch.dataset.crmType == 'info') {
                        formData.set('UF_CRM_LEAD_POLICY_AGREEMENT', ch.id);
                    }
                });
                // Выберите город
                e.target?.classList.add('disabled-btn');
                fetch(action, {
                    method: "POST",
                    body: formData,
                }).then((response) => {
                    response.json().then((response) => {
                        if (response.status == 'error') {
                            const errorsObj = response.errors;
                            e.target?.classList.remove('disabled-btn');
                            for (const [key, value] of Object.entries(errorsObj)) {
                                const error = it.el.querySelector(`.error[for='${key}']`);
                                if (error) {
                                    const relatedField = it.el.querySelector(`#${key}`);
                                    const closestDiv = error.closest('div');
                                    error.textContent = value;
                                    if (closestDiv) {
                                        closestDiv.classList.add('error');
                                        if (closestDiv.classList.contains('b-select')) {
                                            closestDiv.addEventListener('choice', () => {
                                                closestDiv?.classList.remove('error');
                                            });
                                        }
                                        else {
                                            relatedField?.addEventListener("change", () => {
                                                closestDiv?.classList.remove('error');
                                            });
                                        }
                                    }
                                }
                                else { // На случай, когда ошибка у чекбокса
                                    const checkbox = document.getElementById(key);
                                    const closestDiv = checkbox?.closest('div') || null;
                                    closestDiv?.classList.add('error');
                                    checkbox?.addEventListener('change', () => {
                                        closestDiv?.classList.remove('error');
                                    });
                                }
                            }
                        }
                        if (response.status == 'ok') {
                            window.location.href = "/success/";
                        }
                    });
                });
            });
        }
    }
}
Registration.register(FormConstructor);
export default FormConstructor;
