import Block from "../../../../libs/Block";
import Registration from "../../../../libs/Registration";
import "./email.scss";
class Email extends Block {
    static get blockName() {
        return "b-email";
    }
    onInit() {
        const it = this;
        const input = it.el.querySelector("input");
        input?.addEventListener("input", () => {
            this.el.dataset.required && this.validate(it);
        });
        input?.addEventListener("focusout", () => {
            this.el.dataset.required && this.validate(it);
        });
    }
    validate(it) {
        const input = it.el.querySelector("input");
        let label = it.el.querySelector("label");
        const requiredText = it.el.dataset.requiredText;
        const errorText = it.el.dataset.errorText;
        const email_regex = /.+@[^\.].+\..+/;
        if (label && input) {
            if (it.el.dataset.required) {
                if (input.value) {
                    it.el.classList.remove("error");
                    label.innerHTML = "";
                }
                else {
                    it.el.classList.add("error");
                    label.innerHTML = requiredText || "";
                    return;
                }
            }
            if (!email_regex.test(input.value)) {
                it.el.classList.add("error");
                label.innerHTML = errorText || "";
                return;
            }
            else {
                it.el.classList.remove("error");
                label.innerHTML = "";
            }
        }
    }
}
Registration.register(Email);
export default Email;
