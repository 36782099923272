import Block from "../../../../libs/Block";
import Registration from "../../../../libs/Registration";
import "./default.scss";
class Default extends Block {
    static get blockName() {
        return "b-default";
    }
    onInit() {
        const it = this;
        const input = it.el.querySelector("input");
        input?.addEventListener("input", () => {
            this.el.dataset.required && this.validate(it);
        });
        input?.addEventListener("focusout", () => {
            this.el.dataset.required && this.validate(it);
        });
    }
    validate(it) {
        const input = it.el.querySelector("input");
        const label = it.el.querySelector("label");
        const requiredText = it.el.dataset.requiredText;
        if (label && input) {
            if (it.el.dataset.required) {
                if (input.value) {
                    it.el.classList.remove("error");
                    label.innerHTML = "";
                }
                else {
                    it.el.classList.add("error");
                    label.innerHTML = requiredText || "";
                    return;
                }
            }
        }
    }
}
Registration.register(Default);
export default Default;
