import Block from "../../../../libs/Block";
import Registration from "../../../../libs/Registration";
import Choices from "choices.js";
import "./select.scss";
class Select extends Block {
    choices;
    static get blockName() {
        return "b-select";
    }
    onInit() {
        const it = this;
        const select = it.el.querySelector(`.${Select.blockName}__select`);
        const searchSelect = it.el.dataset.searchSelect;
        if (select) {
            if (!searchSelect) {
                this.choices = new Choices(select, {
                    noChoicesText: 'Нет вариантов на выбор',
                    itemSelectText: '',
                    searchChoices: false,
                    searchEnabled: false,
                    shouldSort: false,
                });
            }
            else {
                this.choices = new Choices(select, {
                    noChoicesText: 'Нет вариантов на выбор',
                    itemSelectText: '',
                    shouldSort: false,
                });
            }
        }
    }
}
Registration.register(Select);
export default Select;
