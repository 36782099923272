import Block from "../../../../libs/Block";
import Registration from "../../../../libs/Registration";
import intlTelInput from 'intl-tel-input';
import 'intl-tel-input/build/css/intlTelInput.css';
import "./phone.scss";
import IMask from 'imask';
class Phone extends Block {
    static get blockName() {
        return "b-phone";
    }
    get value() {
        const it = this;
        const phone = it.el.querySelector(`.${Phone.blockName}__input`);
        return phone?.value;
    }
    initTel;
    onInit() {
        const it = this;
        const phone = it.el.querySelector(`.${Phone.blockName}__input`);
        if (phone) {
            this.initTel = intlTelInput(phone, {
                initialCountry: "ru",
                separateDialCode: true,
            });
            setTimeout(() => {
                const selected = it.el.querySelector(".iti__selected-country");
                const list = it.el.querySelector(".iti__country-list");
                let mask = null;
                if (selected && list) {
                    if (selected.title == "Russia") {
                        const maskOptions = {
                            mask: "(000)000-00-00"
                        };
                        mask = IMask(phone, maskOptions);
                    }
                    list.addEventListener("click", () => {
                        setTimeout(() => {
                            if (selected.title == "Russia") {
                                mask?.destroy();
                                const maskOptions = {
                                    mask: "(000)000-00-00"
                                };
                                mask = IMask(phone, maskOptions);
                            }
                            else {
                                mask?.destroy();
                            }
                        }, 0);
                    });
                }
            }, 0);
        }
        phone?.addEventListener("input", () => {
            this.el.dataset.required && this.validate(it);
        });
        phone?.addEventListener("focusout", () => {
            this.el.dataset.required && this.validate(it);
        });
    }
    validate(it) {
        const phone = it.el.querySelector(`.${Phone.blockName}__input`);
        let label = it.el.querySelector("label");
        const requiredText = it.el.dataset.requiredText;
        if (label && phone) {
            if (it.el.dataset.required) {
                if (phone.value) {
                    it.el.classList.remove("error");
                    label.innerHTML = "";
                }
                else {
                    it.el.classList.add("error");
                    label.innerHTML = requiredText || "";
                    return;
                }
            }
        }
    }
}
Registration.register(Phone);
export default Phone;
