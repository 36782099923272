import Block from "../../../../libs/Block";
import Registration from "../../../../libs/Registration";
import "./checkbox.scss";
class Checkbox extends Block {
    static get blockName() {
        return "b-checkbox";
    }
    onInit() {
        const it = this;
        const checkbox = it.el.querySelector("input[type='checkbox']");
        checkbox?.addEventListener("click", () => {
            this.el.dataset.required && this.validate(it);
        });
    }
    validate(it) {
        const checkbox = it.el.querySelector("input[type='checkbox']");
        if (checkbox) {
            if (it.el.dataset.required) {
                if (checkbox.checked) {
                    it.el.classList.remove("error");
                }
                else {
                    it.el.classList.add("error");
                    return;
                }
            }
        }
    }
}
Registration.register(Checkbox);
export default Checkbox;
